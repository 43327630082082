var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"table-parent",attrs:{"show-expand":"","headers":_vm.headersTable1,"items-per-page":100,"items":_vm.item,"item-key":"id","hide-default-header":"","hide-default-footer":"","server-items-length":1000},scopedSlots:_vm._u([{key:"item.checkedAll",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"hide-details":"","label":item.accessMenuLabel},on:{"change":function ($event) { return _vm.$emit('toggleCheckedAllReport', $event, item); }},model:{value:(item.checkedAll),callback:function ($$v) {_vm.$set(item, "checkedAll", $$v)},expression:"item.checkedAll"}})]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-chip',{staticClass:"text-capitalize white--text",attrs:{"color":"black","label":""}},[_vm._v(" Laporan: "+_vm._s(item.reports.filter(function (x) { return x.checked; }).length)+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{class:['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v(" $expand ")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var targetItem = ref.item;
return [_c('td',{staticClass:"white",attrs:{"colspan":3}},[_c('v-data-table',{staticClass:"pl-4",attrs:{"items-per-page":100,"headers":_vm.headersTable2,"items":targetItem.reports,"item-key":"id","hide-default-header":"","hide-default-footer":"","server-items-length":1000},scopedSlots:_vm._u([{key:"item.checkedAllFunction",fn:function(ref){
var itemFunction = ref.item;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","label":itemFunction.accessMenuLabel},on:{"change":function ($event) { return _vm.$emit('handleChangeReportItem', $event, itemFunction); }},model:{value:(itemFunction.checked),callback:function ($$v) {_vm.$set(itemFunction, "checked", $$v)},expression:"itemFunction.checked"}})]}}],null,true)})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }