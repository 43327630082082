var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"rounded":""}},[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Role - "+_vm._s(_vm.$route.query.rolesName.replace('_', ' ')))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"show-expand":"","expanded":_vm.expanded,"items-per-page":100,"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"item-key":"id","hide-default-header":"","hide-default-footer":"","server-items-length":1000,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.checkedAll",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","label":item.menuName},on:{"change":function ($event) { return _vm.toggleCheckedAll($event, item); }},model:{value:(item.checkedAll),callback:function ($$v) {_vm.$set(item, "checkedAll", $$v)},expression:"item.checkedAll"}})]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-chip',{staticClass:"text-capitalize white--text",attrs:{"color":"black","label":""}},[_vm._v(" Menu : "+_vm._s(_vm.getTotalCheckedMenu(item))+" | fungsi: "+_vm._s(_vm.getTotalCheckedFunctionMenu(item))+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{class:['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v(" $expand ")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('ExpandedItemList',{attrs:{"item":item,"colspanLength":3},on:{"toggleCheckedAllSubMenu":_vm.toggleCheckedAllSubMenu,"handleChangeMenuFunction":_vm.handleChangeMenuFunction,"toggleCheckedAllReport":_vm.toggleCheckedAllReport,"handleChangeReportItem":_vm.handleChangeReportItem}})]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" Detail Role "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1),(_vm.items.length && _vm.userAccess.canUpdate)?_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2 white--text",attrs:{"depressed":"","color":"primary","outlined":""},on:{"click":_vm.resetField}},[_vm._v(" "+_vm._s(_vm.$_strings.common.RESET)+" ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary","loading":_vm.submiting},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$_strings.common.SAVE)+" ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }