<template>
  <td
    class="white"
    :colspan="colspanLength"
  >
    <v-container fluid>
      <v-sheet outlined class="pa-2 rounded">
        <v-data-table
          v-if="item.childMenu.length"
          show-expand
          :items-per-page="100"
          :headers="headersTable1"
          :items="item.childMenu"
          item-key="id"
          hide-default-header
          hide-default-footer
          :server-items-length="1000"
        >
          <template v-slot:[`item.checkedAll`]="{item}">
            <v-checkbox
              class="ma-0 pa-0"
              hide-details
              v-model="item.checkedAll"
              @change="($event) => $emit('toggleCheckedAllSubMenu', $event, item)"
              :label="item.menuName"
            >
            </v-checkbox>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
            <v-row class="d-flex justify-end">
              <v-col cols="4">
                <v-chip
                  color="black"
                  label
                  class="text-capitalize white--text"
                >
                  fungsi: {{ item.menuFunction.filter((x) => x.checked).length }}
                </v-chip>
              </v-col>
              <v-col cols="auto">
                <v-icon
                  :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
                  @click.stop="expand(!isExpanded)"
                >
                  $expand
                </v-icon>
              </v-col>
            </v-row>
          </template>
          <template class="d-none" v-slot:expanded-item="{ headers, item:targetItem }">
            <td
              class="white"
              :colspan="3"
            >
              <v-data-table
                :items-per-page="100"
                :headers="headersTable2"
                :items="targetItem.menuFunction"
                item-key="id"
                hide-default-header
                hide-default-footer
                :server-items-length="1000"
              >
                <template v-slot:[`item.checkedAllFunction`]="{item:itemFunction}">
                  <v-checkbox
                    class="ma-0 pa-0"
                    hide-details
                    v-model="itemFunction.checked"
                    :label="itemFunction.accessMenuLabel"
                    @change="($event) => $emit('handleChangeMenuFunction', $event, item)"
                  >
                  </v-checkbox>
                  <section class="mt-2" v-if="itemFunction.reports && itemFunction.reports.length">
                    <ExpandedReports
                      :item="itemFunction.reports"
                      @toggleCheckedAllReport="(event, item) => $emit('toggleCheckedAllReport', event, item, targetItem)"
                      @handleChangeReportItem="(event, item) => $emit('handleChangeReportItem', targetItem)"
                    />
                  </section>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
        <v-data-table
          v-else
          :items-per-page="100"
          :headers="headersTable2"
          :items="item.menuFunction"
          item-key="id"
          hide-default-header
          hide-default-footer
          :server-items-length="1000"
        >
          <template v-slot:[`item.checkedAllFunction`]="{item:itemFunction}">
            <v-checkbox
              class="ma-0 pa-0"
              hide-details
              v-model="itemFunction.checked"
              :label="itemFunction.accessMenuLabel"
              @change="($event) => $emit('handleChangeMenuFunction', $event, item)"
            >
            </v-checkbox>
          </template>
        </v-data-table>
      </v-sheet>
    </v-container>
  </td>
</template>

<script>

import ExpandedReports from './ExpandedReports.vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    colspanLength: {
      type: Number,
      default: () => 3,
    },
  },
  components: {
    ExpandedReports,
  },
  data() {
    return {
      isLoading: false,
      headersTable1: [
        {
          text: '',
          value: 'checkedAll',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
          class: 'black--text amber accent-3',
          cellClass: 'clickable',
        },
      ],
      headersTable2: [
        {
          text: '',
          value: 'checkedAllFunction',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    items() {
      if (this.item.childMenu.length) {
        return this.item.childMenu;
      }
      return this.item.menuFunction;
    },
  },
};
</script>
