var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"white",attrs:{"colspan":_vm.colspanLength}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"pa-2 rounded",attrs:{"outlined":""}},[(_vm.item.childMenu.length)?_c('v-data-table',{attrs:{"show-expand":"","items-per-page":100,"headers":_vm.headersTable1,"items":_vm.item.childMenu,"item-key":"id","hide-default-header":"","hide-default-footer":"","server-items-length":1000},scopedSlots:_vm._u([{key:"item.checkedAll",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","label":item.menuName},on:{"change":function ($event) { return _vm.$emit('toggleCheckedAllSubMenu', $event, item); }},model:{value:(item.checkedAll),callback:function ($$v) {_vm.$set(item, "checkedAll", $$v)},expression:"item.checkedAll"}})]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-chip',{staticClass:"text-capitalize white--text",attrs:{"color":"black","label":""}},[_vm._v(" fungsi: "+_vm._s(item.menuFunction.filter(function (x) { return x.checked; }).length)+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{class:['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v(" $expand ")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var targetItem = ref.item;
return [_c('td',{staticClass:"white",attrs:{"colspan":3}},[_c('v-data-table',{attrs:{"items-per-page":100,"headers":_vm.headersTable2,"items":targetItem.menuFunction,"item-key":"id","hide-default-header":"","hide-default-footer":"","server-items-length":1000},scopedSlots:_vm._u([{key:"item.checkedAllFunction",fn:function(ref){
var itemFunction = ref.item;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","label":itemFunction.accessMenuLabel},on:{"change":function ($event) { return _vm.$emit('handleChangeMenuFunction', $event, _vm.item); }},model:{value:(itemFunction.checked),callback:function ($$v) {_vm.$set(itemFunction, "checked", $$v)},expression:"itemFunction.checked"}}),(itemFunction.reports && itemFunction.reports.length)?_c('section',{staticClass:"mt-2"},[_c('ExpandedReports',{attrs:{"item":itemFunction.reports},on:{"toggleCheckedAllReport":function (event, item) { return _vm.$emit('toggleCheckedAllReport', event, item, targetItem); },"handleChangeReportItem":function (event, item) { return _vm.$emit('handleChangeReportItem', targetItem); }}})],1):_vm._e()]}}],null,true)})],1)]}}],null,true)}):_c('v-data-table',{attrs:{"items-per-page":100,"headers":_vm.headersTable2,"items":_vm.item.menuFunction,"item-key":"id","hide-default-header":"","hide-default-footer":"","server-items-length":1000},scopedSlots:_vm._u([{key:"item.checkedAllFunction",fn:function(ref){
var itemFunction = ref.item;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","label":itemFunction.accessMenuLabel},on:{"change":function ($event) { return _vm.$emit('handleChangeMenuFunction', $event, _vm.item); }},model:{value:(itemFunction.checked),callback:function ($$v) {_vm.$set(itemFunction, "checked", $$v)},expression:"itemFunction.checked"}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }