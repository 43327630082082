<template>
  <v-container fluid>
    <v-sheet rounded class="pa-4">
      <v-row>
        <v-col>
          <h3>Role - {{$route.query.rolesName.replace('_', ' ')}}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            show-expand
            :expanded.sync="expanded"
            :items-per-page="100"
            :loading="isLoading"
            :headers="headers"
            :items="items"
            item-key="id"
            hide-default-header
            hide-default-footer
            :server-items-length="1000"
            :options.sync="pagination"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          >
            <template v-slot:[`item.checkedAll`]="{item}">
              <v-checkbox
                class="ma-0 pa-0"
                hide-details
                @change="($event) => toggleCheckedAll($event, item)"
                v-model="item.checkedAll"
                :label="item.menuName"
              >
              </v-checkbox>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
              <v-row class="d-flex justify-end">
                <v-col cols="4">
                  <v-chip
                    color="black"
                    label
                    class="text-capitalize white--text"
                  >
                    Menu : {{ getTotalCheckedMenu(item) }} | fungsi: {{ getTotalCheckedFunctionMenu(item) }}
                  </v-chip>
                </v-col>
                <v-col cols="auto">
                  <v-icon
                    :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
                    @click.stop="expand(!isExpanded)"
                  >
                    $expand
                  </v-icon>
                </v-col>
              </v-row>
            </template>
            <template class="d-none" v-slot:expanded-item="{ headers, item }">
              <ExpandedItemList
                :item="item"
                :colspanLength="3"
                @toggleCheckedAllSubMenu="toggleCheckedAllSubMenu"
                @handleChangeMenuFunction="handleChangeMenuFunction"
                @toggleCheckedAllReport="toggleCheckedAllReport"
                @handleChangeReportItem="handleChangeReportItem"
              />
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                Detail Role
                <span v-if="items.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-if="items.length && userAccess.canUpdate" cols="12" class="d-flex justify-end">
          <v-btn
            depressed
            class="mr-2 white--text"
            color="primary"
            outlined
            @click="resetField"
          >
            {{$_strings.common.RESET}}
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="submit"
            :loading="submiting"
          >
            {{$_strings.common.SAVE}}
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
} from '@/helper/commonHelpers';
import ExpandedItemList from './ExpandedItemList.vue';

export default {
  name: 'detail-role',
  components: {
    ExpandedItemList,
  },
  data() {
    return {
      submiting: false,
      isLoading: false,
      headers: [
        {
          text: '',
          value: 'checkedAll',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
          cellClass: 'clickable',
        },
      ],
      expanded: [],
      defaultItems: [],
      items: [],
      totalData: 0,
      pagination: {
        page: 1,
        itemsPerPage: 100,
      },
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    toggleCheckedAllReport(event, item, targetItem) {
      this.items = this.items.map((i) => {
        if (i.id === targetItem.parentMenuId) {
          if (i.childMenu && i.childMenu.length) {
            return {
              ...i,
              childMenu: i.childMenu.map((c) => ({
                ...c,
                menuFunction: c.menuFunction.map((cm) => ({
                  ...cm,
                  reports: cm.reports ? cm.reports.map((cr) => {
                    if (cr.id === item.id) {
                      return {
                        ...cr,
                        reports: cr.reports.map((r) => ({
                          ...r,
                          checked: event,
                        })),
                      };
                    }
                    return cr;
                  }) : cm.reports,
                })),
              })),
            };
          }
        }
        return {
          ...i,
        };
      });
      this.items = this.items.map((i) => {
        if (i.childMenu && i.childMenu.length) {
          let checkedAll = i.childMenu.some((c) => c.menuFunction.some((x) => x.checked));
          i.childMenu.forEach((c) => {
            if (c.menuFunction && !checkedAll) {
              c.menuFunction.forEach((cm) => {
                if (cm.reports && !checkedAll) checkedAll = cm.reports.some((r) => r.checkedAll);
              });
            }
          });
          return {
            ...i,
            checkedAll,
            childMenu: i.childMenu.map((c) => {
              let _checkedAll = c.menuFunction.some((x) => x.checked);
              c.menuFunction.forEach((cm) => {
                if (cm.reports && !_checkedAll) _checkedAll = cm.reports.some((r) => r.checkedAll);
              });
              return {
                ...c,
                checkedAll: _checkedAll,
              };
            }),
          };
        }
        return i;
      });
    },
    handleChangeReportItem(targetItem) {
      this.items = this.items.map((i) => {
        if (i.id === targetItem.parentMenuId) {
          if (i.childMenu && i.childMenu.length) {
            return {
              ...i,
              childMenu: i.childMenu.map((c) => ({
                ...c,
                menuFunction: c.menuFunction.map((cm) => ({
                  ...cm,
                  reports: cm.reports ? cm.reports.map((cr) => ({
                    ...cr,
                    checkedAll: cr.reports.some((x) => x.checked),
                  })) : cm.reports,
                })),
              })),
            };
          }
        }
        return {
          ...i,
        };
      });
    },
    handleChangeMenuFunction(event, item) {
      this.items = this.items.map((i) => {
        if (item.id === i.id) {
          if (item.childMenu.length) {
            let checkedAllParent = i.childMenu.some((c) => c.menuFunction.some((m) => m.checked));
            i.childMenu.forEach((x) => {
              x.menuFunction.forEach((f) => {
                if (f.reports && !checkedAllParent) checkedAllParent = f.reports.some((r) => r.checkedAll);
              });
            });
            return {
              ...i,
              checkedAll: checkedAllParent,
              childMenu: i.childMenu.map((ic) => {
                let checkedAll = ic.menuFunction.some((m) => m.checked);
                ic.menuFunction.forEach((m) => {
                  if (m.reports && !checkedAll) checkedAll = m.reports.some((x) => x.checkedAll);
                });
                return {
                  ...ic,
                  checkedAll,
                };
              }),
            };
          }
          return {
            ...i,
            checkedAll: i.menuFunction.some((m) => m.checked),
          };
        }
        return i;
      });
    },
    toggleCheckedAllSubMenu(event, item) {
      const newDataItems = this.items.map((i) => {
        if (item.parentMenuId === i.id) {
          return {
            ...i,
            menuFunction: i.menuFunction.map((m) => ({
              ...m,
              checked: event,
            })),
            childMenu: i.childMenu.map((c) => {
              if (c.id === item.id) {
                return {
                  ...c,
                  checkedAll: event,
                  menuFunction: c.menuFunction.map((m) => ({
                    ...m,
                    checked: event,
                    reports: m.reports && m.reports.map((mr) => ({
                      ...mr,
                      checkedAll: event,
                      reports: mr.reports && mr.reports.map((mrc) => ({
                        ...mrc,
                        checked: event,
                      })),
                    })),
                  })),
                };
              }
              return {
                ...c,
              };
            }),
          };
        }
        return i;
      });
      this.items = newDataItems.map((i) => {
        if (i.childMenu.length) {
          return {
            ...i,
            checkedAll: i.childMenu.some((c) => c.menuFunction.some((m) => m.checked)),
          };
        }
        return {
          ...i,
          checkedAll: i.menuFunction.some((m) => m.checked),
        };
      });
    },
    toggleCheckedAll(event, item) {
      this.items = this.items.map((i) => {
        if (item.id === i.id) {
          return {
            ...i,
            checkedAll: event,
            menuFunction: i.menuFunction.map((m) => ({
              ...m,
              checked: event,
            })),
            childMenu: i.childMenu.map((c) => ({
              ...c,
              checkedAll: event,
              menuFunction: c.menuFunction.map((m) => ({
                ...m,
                checked: event,
                reports: m.reports && m.reports.map((mr) => ({
                  ...mr,
                  checkedAll: event,
                  reports: mr.reports && mr.reports.map((mrc) => ({
                    ...mrc,
                    checked: event,
                  })),
                })),
              })),
            })),
          };
        }
        return i;
      });
    },
    dataMenu(item) {
      if (item.childMenu && item.childMenu.length) return item.childMenu;
      return [item];
    },
    checkObjectPermission(item, objName) {
      return Object.keys(item).includes(objName);
    },
    navigateToDetailRole(item) {
      this.$router.push({
        name: 'detail-role',
        params: {
          roleId: item.id,
        },
      });
    },
    showDialogCreateEditRole(item) {
      if (item) {
        this.$refs.dialogCreateEditRole.id = item.id;
        this.$refs.dialogCreateEditRole.form.roleName = item.roleName;
      }
      this.$refs.dialogCreateEditRole.dialog = true;
    },
    resetField() {
      this.items = JSON.parse(JSON.stringify(this.defaultItems));
    },
    setMenuFunctionItem(item, menuPermission) {
      return item.menuFunction.map((dm) => {
        const menuF = menuPermission.find((m) => m.menuId === item.id);
        if (menuF && menuF.menuFunction.length && menuF.menuFunction.find((x) => x.id === dm.id)) {
          return {
            ...dm,
            checked: true,
          };
        }
        return {
          ...dm,
          checked: false,
        };
      });
    },
    getTotalCheckedFunctionMenu(item) {
      if (item.childMenu.length) {
        return item.childMenu.reduce((newVal, curr) => newVal + curr.menuFunction.filter((x) => x.checked).length, 0);
      }
      return item.menuFunction.filter((x) => x.checked).length;
    },
    getTotalCheckedMenu(item) {
      if (item.childMenu.length) {
        return item.childMenu.filter((x) => x.checkedAll).length;
      }
      return 0;
    },
    setCheckedAllCheckboxReport(reports, menuId, menuPermission) {
      const permissionReport = menuPermission.find((x) => x.menuId === menuId);
      if (permissionReport.menuFunction) {
        const ids = permissionReport.menuFunction.map((x) => x.id);
        return reports.some((r) => ids.includes(r.id));
      }
      return false;
    },
    setCheckedItemReport(reportId, menuId, menuPermission) {
      const permissionReport = menuPermission.find((x) => x.menuId === menuId);
      if (permissionReport.menuFunction) {
        const ids = permissionReport.menuFunction.map((x) => x.id);
        return ids.includes(reportId);
      }
      return false;
    },
    async fetchData() {
      const { roleId } = this.$route.params;
      const { appType } = this.$route.query;
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filter = skipEmptyStringObject({
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
      });
      try {
        this.isLoading = true;
        const resultMenu = await this.$_services.menu.fetchMenu({ appType });
        const resultPermission = await this.$_services.role.fetchDetailRole({ roleId, filter });
        const items = resultMenu.data.map((d) => ({
          ...d,
          menuFunction: this.setMenuFunctionItem(d, resultPermission.data),
          childMenu: d.childMenu.map((dc) => ({
            ...dc,
            menuFunction: this.setMenuFunctionItem(dc, resultPermission.data),
          })),
        }));
        const dataItems = [];
        items.forEach((i) => {
          if (i.childMenu.length) {
            const checkedAll = i.childMenu.some((x) => x.menuFunction.some((y) => y.checked));
            dataItems.push({
              ...i,
              childMenu: i.childMenu.map((ic) => ({
                ...ic,
                checkedAll: ic.menuFunction.some((y) => y.checked),
                menuFunction: ic.menuFunction.map((iF) => {
                  if (iF.reports && iF.reports.length) {
                    return {
                      ...iF,
                      reports: iF.reports.map((icF) => ({
                        ...icF,
                        checkedAll: this.setCheckedAllCheckboxReport(icF.reports, ic.id, resultPermission.data),
                        reports: icF.reports ? icF.reports.map((ir) => ({
                          ...ir,
                          checked: this.setCheckedItemReport(ir.id, ic.id, resultPermission.data),
                        })) : [],
                      })),
                    };
                  }
                  return {
                    ...iF,
                  };
                }),
              })),
              checkedAll,
            });
          } else {
            const checkedAll = i.menuFunction.some((y) => y.checked);
            dataItems.push({
              ...i,
              checkedAll,
            });
          }
        });
        this.items = dataItems;
        this.defaultItems = JSON.parse(JSON.stringify(this.items));
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      const { roleId } = this.$route.params;
      let childMenu = [];
      this.items.forEach((x) => {
        if (x.childMenu.length) {
          childMenu = [...childMenu, ...x.childMenu];
        }
      });
      const allMenu = [...this.items, ...childMenu];
      const form = allMenu.map((m) => {
        let menuF = [...m.menuFunction.filter((mf) => mf.checked).map((mf) => ({ id: mf.id }))];
        m.menuFunction.forEach((xm) => {
          if (xm.reports && xm.reports.length) {
            menuF = [...menuF, ...xm.reports.filter((x) => x.checkedAll).map((x) => ({ id: x.id }))];
            xm.reports.forEach((x) => {
              if (x.reports && x.reports.length) {
                menuF = [...menuF, ...x.reports.filter((y) => y.checked).map((y) => ({ id: y.id }))];
              }
            });
          }
        });
        return {
          menuId: m.id,
          menuFunction: [...menuF],
        };
      });
      try {
        this.submiting = true;
        await this.$_services.role.updateRoleMenu({ roleId, form });
        this.defaultItems = JSON.parse(JSON.stringify(this.items));
        this.$dialog.notify.success('Berhasil memperbarui');
      } finally {
        this.submiting = false;
      }
    },
  },
};
</script>
