<template>
  <v-data-table
    show-expand
    class="table-parent"
    :headers="headersTable1"
    :items-per-page="100"
    :items="item"
    item-key="id"
    hide-default-header
    hide-default-footer
    :server-items-length="1000"
  >
    <template v-slot:[`item.checkedAll`]="{item}">
      <v-checkbox
        class="pa-0 ma-0"
        hide-details
        v-model="item.checkedAll"
        @change="($event) => $emit('toggleCheckedAllReport', $event, item)"
        :label="item.accessMenuLabel"
      >
      </v-checkbox>
    </template>
    <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
      <v-row class="d-flex justify-end">
        <v-col cols="4">
          <v-chip
            color="black"
            label
            class="text-capitalize white--text"
          >
            Laporan: {{ item.reports.filter((x) => x.checked).length }}
          </v-chip>
        </v-col>
        <v-col cols="auto">
          <v-icon
            :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
            @click.stop="expand(!isExpanded)"
          >
            $expand
          </v-icon>
        </v-col>
      </v-row>
    </template>
    <template class="d-none" v-slot:expanded-item="{ headers, item:targetItem }">
      <td
        class="white"
        :colspan="3"
      >
        <v-data-table
          :items-per-page="100"
          :headers="headersTable2"
          :items="targetItem.reports"
          item-key="id"
          hide-default-header
          hide-default-footer
          :server-items-length="1000"
          class="pl-4"
        >
          <template v-slot:[`item.checkedAllFunction`]="{item:itemFunction}">
            <v-checkbox
              class="ma-0 pa-0"
              hide-details
              v-model="itemFunction.checked"
              :label="itemFunction.accessMenuLabel"
              @change="($event) => $emit('handleChangeReportItem', $event, itemFunction)"
            >
            </v-checkbox>
          </template>
        </v-data-table>
      </td>
    </template>
  </v-data-table>
</template>

<script>

export default {
  name: 'expand-reports',
  props: {
    item: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      headersTable1: [
        {
          text: '',
          value: 'checkedAll',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
          class: 'black--text amber accent-3',
          cellClass: 'clickable',
        },
      ],
      headersTable2: [
        {
          text: '',
          value: 'checkedAllFunction',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ],
    };
  },
};

</script>

<style scoped lang="scss">
.v-data-table::v-deep {
    table {
      tr {
        td {
          padding: 0px !important;
          overflow: hidden;
        }
      }
    }
  }
</style>
